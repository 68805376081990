@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
body {
  margin: 0;
  font-family: 'Raleway', serif !important;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'icons';
@import 'typewriter';
@import 'docs';
